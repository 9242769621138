import React from 'react';
import '../styles/main.scss';
import Footer from './Footer';
import Navbar from './Navbar';
import Helmet from 'react-helmet';
import favicon from '../images/favicon.png';

const Layout = ({ children }) => (
  <div className='App'>
    <Helmet>
      <title>Sarah Solheim</title>
      <meta name='description' content='Art Director Personal Website' />
      <meta name='keywords' content='art director, art, artist' />
      <meta name='og:title' content='Sarah Solheim' />
      <meta name='og:type' content='website' />
      <meta name='og:description' content='Art Director Personal Website' />
      <meta name='og:locale' content='en_US' />
      <meta name='og:url' content='https://sarahsolheim.com' />
      <link rel='canoical' href='https://sarahsolheim.com' />
      <link rel='icon' href={favicon} />
    </Helmet>
    <Navbar />
    {children}
    <Footer />
  </div>
);

export default Layout;
