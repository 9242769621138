import React from 'react';
import Link from 'gatsby-link';

const Navbar = () => {
  return (
    <nav>
      <ul className='left'>
        <li>
          <Link to='/'>sarah solheim</Link>
        </li>
      </ul>
      <ul className='right'>
        <li>
          <Link to='/'>home</Link>
        </li>
        <li>
          <Link to='/work'>work</Link>
        </li>
        <li>
          <Link to='/contact'>contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
